<template>
  <div class="material-upload">
    <el-upload class="mc-material-upload" ref="upload" :action="url" :on-preview="handlePreview" :multiple="false" :on-success="afterUpload"
      :http-request="handleUploadRequest" :headers="headers" :show-file-list="showFile" :on-change="pushFileList" :on-error="uploadErr"
      :file-list="fileList" :limit="limitCount" :on-remove="handleRemove" :accept="accept" :on-exceed="onExceed">
      <el-button type="text" :loading="isloading" class="mc-header-btn">
        <i :class="`${iconName}  mc-header-btn-icon`"></i>
        {{btnName}}
      </el-button>
    </el-upload>
    <div v-if="uploading" class="mc-upload-status">
      <div class="mc-upload-header">上传进度</div>
      <div class="mc-upload-detail">
        <el-progress :percentage="percentage" v-if="isloading || percentage==100"></el-progress>
      </div>
    </div>

    <!-- <div class="form-horizontal" style="margin-top:80px;">
      <div class="form-group">
        <div class="col-md-10">
          <input name="fileInput" id="fileInput" type="file" ref="fileInput" />
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-offset-2 col-md-10">
          <input type="submit" id="submit" value="上传" class="btn btn-success" @click="handleSubmit" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// import { fetchFileList } from '@/api/file'
import md5 from "js-md5";
var pLimit = require('p-limit');
export default {
  name: "UploadMaterial",
  // inject: ['editUpload'],
  inject: {
    editUpload: {
      default: () =>
        function () {
          console.log("不存在此函数");
        }
    }
  },
  components: {},
  props: {
    placeholder: {
      Type: String,
      default: "请选择"
    },
    showFile: {
      Type: Boolean,
      default: false
    },
    label: {
      Type: String,
      default: "选择文件"
    },
    name: {
      Type: String,
      default: ""
    },
    value: {
      Type: String,
      default: ""
    },
    remarks: {
      type: String,
      default: "只能上传图片、PDF、Word、Excel、PPT、TXT文件，且不超过20M"
    },
    accept: {
      Type: String,
      default: "File"
    },
    limitType: {
      Type: String,
      default: ""
    },
    limitCount: {
      Type: Intl,
      default: 99
    },
    fieldName: {
      Type: String,
      default: "File"
    },
    iconName: {
      Type: String,
      default: "el-icon-upload2"
    },
    btnName: {
      Type: String,
      default: "上传文件"
    },
    remarkText: {
      Type: String,
      default: "只能上传图片、文档及视频文件"
    },
    apiUrl: {
      type: Object,
      default() {
        return {
          baseApi: process.env.VUE_APP_BASE_API,
          uploadSingle: "/File/UploadSingleFile",
          uploadChunk: "/File/UploadChunk",
          mergeChunk: "/File/MergeChunk",
          getFiles: "/File/GetListByIds",
        };
      }
    },
    pieceSize: {
      type: Number,
      default: 5
    },
    thread: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      form: {
        Headline: "",
        IssueTime: "",
        ContentFile: ""
      },
      url: "",
      headers: {},
      fileList: [],
      fileLists: [], // 上传后的文件列表
      tableForm: [],
      fileNum: 0,
      isloading: false,
      percentage: 0,
      fileId: "",
      uploading: false,
      timer: "",
      input: [],
      totalParts: 0,
      successPart: 0,
      videoDuration: 0,
    };
  },
  mounted() {

    if (this.value && this.showFile) {
      this.fileList = this.getFiles();
    }
  },
  watch: {
    // fileList(fileList) {
    //   console.log(333,fileList)
    //
    //   this.$nextTick(() => {
    //     this.dealUpload();
    //   });
    // }
  },
  methods: {
    handleSubmit() {
      var files = this.$refs.fileInput.files
      debugger
      this.dealUpload(files[0])
    },
    getDuration(file) {
      var url = URL.createObjectURL(file);
      var oVideo = document.createElement('video');
      oVideo.setAttribute('src', url);
      oVideo.oncanplay = () => {
        this.videoDuration = oVideo.duration
      }
    },
    onSubmit() { },
    // 上传请求
    handleUploadRequest(back) {
      // this.loading = true
      console.log("请示上传的文件:", back);
      // 清空原来的
      this.fileLists = [];
      this.fileLists.push(back.file);
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 处理上传文件
    dealUpload(file) {
      this.isloading = true;
      this.uploading = true;
      var fileChunks = [];
      // 设置分片缓冲区大小
      var maxFileSizeMB = this.pieceSize;
      var bufferChunkSize = maxFileSizeMB * (1024 * 1024);
      // 读取文件流其实位置
      var fileStreamPos = 0;
      // 设置下一次读取缓冲区初始大小
      var endPos = bufferChunkSize;
      // 文件大小
      var size = file.size;
      // 将文件进行循环分片处理塞入分片数组
      while (fileStreamPos < size) {
        var fileChunkInfo = {
          file: file.slice(fileStreamPos, endPos),
          start: fileStreamPos,
          end: endPos
        }
        fileChunks.push(fileChunkInfo);
        fileStreamPos = endPos;
        endPos = fileStreamPos + bufferChunkSize;
      }
      // 获取上传文件分片总数量
      var totalParts = fileChunks.length;
      this.totalParts = totalParts;
      this.successPart = 0; 
      console.log('fileChunks', fileChunks, fileChunks.length)
      //控制并发上传数
      const limit = pLimit(this.thread);
      var input = []; 
      for (let index = 0; index < fileChunks.length; index++) {
        //循环体内不能用var定义，limit方法体内用的变量也必须在循环体内定义，不然是nndified
        const chunk = fileChunks[index];
        const partCount=index+1;
        const filePartName = file.name + ".partNumber-" + partCount
        chunk.filePartName = filePartName;
        // url参数
        const url = `partNumber=${partCount}&chunks=${totalParts}&size=${bufferChunkSize}&start=${chunk.start}&end=${chunk.end}&total=${size}&uid=${file.uid}`;
        chunk.urlParameter = url;
        console.log(111,chunk)
        // 上传文件
        //  this.UploadFileChunk(chunk); 
         input.push(limit(() => {
              return new Promise((resolve, reject) => {
                console.log(222,chunk)
                 // 调用上传每一片 
                resolve(this.UploadFileChunk(chunk)); 
              })
            })); 
      }
      // var partCount = 0;
      // var chunk = '';
      // while (chunk = fileChunks.shift()) {
      //   partCount++;
      //   // 上传文件命名约定
      //   var filePartName = file.name + ".partNumber-" + partCount;
      //   chunk.filePartName = filePartName;
      //  // url参数
      //   var url = `partNumber=${partCount}&chunks=${totalParts}&size=${bufferChunkSize}&start=${chunk.start}&end=${chunk.end}&total=${size}&uid=${file.uid}`;
      //   chunk.urlParameter = url;
      //   // 上传文件
      //   //  this.UploadFileChunk(chunk);
      //   var newChunK=Object.assign({},chunk) ;
      //   var thread=limit(() => this.UploadFileChunk(newChunK))
      //   input.push(thread);

      // } 
    },
    uploadFinish() {
      this.isloading = false;
      this.uploading = false;
      this.percentage = 0;
    },
    handlePreview(file) { },
    afterUpload(res, file, fileList) {
      this.fileNum += 1;
      this.emitStatu();
      // this.tableForm.push({
      //   Headline: file.name.substring(0, file.name.lastIndexOf(".")),
      //   ContentFile: res.Result.fileId,
      //   size: file.size,
      //   MaterialType: this.typeId,
      //   TableId: this.tableId,
      //   School: this.sid,
      //   Major: this.majorId
      // });
    },
    pushFileList(file, fileList) {
      console.log('pushFile:', file)
      const fileType = file.raw.type
      const fileSize = file.raw.size / 1024 / 1024;
      console.log('beforeUpload', file)
      var canUpload = true
      if (fileSize > 1024) {
        this.$message.error('单个文件大小不能大于1G');
        canUpload = false;
      }
      else if (this.limitType) {
        if (this.limitType.indexOf(fileType) == -1) {
          this.$message.error(`${remarkText}`);
          canUpload = false;
        }
      }
      if (fileType == 'video/mp4') {
        this.getDuration(file.raw)
      }
      if (canUpload) {
        if (!this.fileList.find(e => e.uid === file.uid)) {
          this.fileList.push(file);
          console.log(5555, this.fileList);
          this.$nextTick(() => {
            this.dealUpload(file.raw);
          });
        }

      }
      else {
        var index = fileList.findIndex(p => p.uid === file.uid)
        if (index > -1) {
          fileList.splice(index, 1)
        }
      }
    },
    emitStatu() {
      this.$emit("change-submit", this.fileList.length !== this.fileNum);
    },
    uploadErr(err, file, fileList) {
      console.log(err);
      this.fileNum += 1;
      this.emitStatu();
      setTimeout(() => {
        this.$message({
          message: "“" + file.name + "”上传失败!",
          type: "error",
          duration: 1000
        });
      }, 1000 * this.fileNum);
    },
    getFiles() {
      var newFiles = [];
      if (this.value) {
        // var fileIds = this.value.join(",");
        var fileIds = this.value

        this.$http.get(`${this.apiUrl.getFiles}?ids=${fileIds}`).then(response => {
          var files = response.Result
          files.map(i => {
            const file = { id: i.Id, name: i.Name, url: i.FullPath }
            newFiles.push(file)
          })
        })
        // fetchFileList(fileIds).then(response => {
        //   var files = response.Result
        //   files.map(i => {
        //     const file = { id: i.Id, name: i.FileName, url: i.ViewFilePath }
        //     newFiles.push(file)
        //   })
        // })
      }
      return newFiles;
    },
    UploadFileChunk(chunk) {
      var data = new FormData();
      data.append("file", chunk.file, chunk.filePartName);
      return this.$http
        .post(`${this.apiUrl.uploadChunk}?${chunk.urlParameter}`, data, {
          contentType: false,
          processData: false,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          timeout: 300000,
        })
        .then(res => { 
          if (res.IsSuccess) {
            var info = res.Result
            console.log('uploadchunk:', info)
            this.successPart += 1;
            this.percentage = Math.ceil((this.successPart / this.totalParts) * 100);
            //所有分片上传完成后合并
            if (this.successPart === this.totalParts) {
              this.$message.success("所有分片已上传，正在合并分片，请稍后...")
              this.$http
                .post(`${this.apiUrl.mergeChunk}?${chunk.urlParameter}`, data, {
                  contentType: false,
                  processData: false,
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                  timeout: 300000,
                }).then(resMerge => {
                  this.timer = setTimeout(this.uploadFinish, 2000); 
                  var result = resMerge.Result
                  result.FileModel.VideoDuration = parseInt(this.videoDuration)
                   this.$message.success("分片已合并，文件成功上传至服务器！")
                  console.log('MergeChunk',result)
                  this.$emit("input", result.FileModel, this.name);
                })

            }
          }
          // progressFun();
          // res.fileName = currentFile.name;
          // success && success(res);
          // successAllCount++;
        })
        .catch(e => {
          error && error(e);
        });
      // $.ajax({
      //     url: '/api/upload/upload?' + chunk.urlParameter,
      //     type: "post",
      //     cache: false,
      //     contentType: false,
      //     processData: false,
      //     data: data,
      // });
    },
    handleRemove(file, fileList) {
      console.log(111, file)
      console.log(222, this.fileList)
      console.log(333, fileList)
      this.fileList = fileList
      //只处理单文件
      if (fileList.length == 0) {
        this.$emit("input", '', this.name);
      }
      // else{
      //    this.$emit("input", '');
      // }
      var index = this.fileList.findIndex(p => p.uid == file.uid)
      if (index > -1) {
        this.fileList.splice(index, 1)
      }
    },
    beforeUpload(file) {

      const fileType = file.type
      const fileSize = file.size / 1024 / 1024;
      console.log('beforeUpload', file)
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // if (this.fileList.length >= this.limitCount) {
      //   this.$message.error('上传文件数量不能大于' + this.limitCount);
      //   return false;
      // }
      if (fileSize > 500) {
        this.$message.error('单个文件大小不能大于500M');
        return false;
      }
      if (this.limitType) {
        if (this.limitType.indexOf(fileType) > -1) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return true;
      }
    },
    onExceed(files, fileList) {
      this.$message.error(`最多只能上传${this.limitCount}个文件！`)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.mc-material-upload {
  width: 100%;
}
.material-upload {
  display: flex;
  padding-top: 1px;
}
.upload-btn {
  display: flex;
  align-items: center;
  &span {
    display: flex;
    align-items: center;
  }
}
.mc-upload-status {
  z-index: 99999;
  width: 638px;
  min-height: 120px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(231, 231, 231, 1);
  .mc-upload-header {
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Microsoft YaHei';
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    display: flex;
    justify-items: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 0px 0px rgba(231, 231, 231, 1);
  }
  .mc-upload-detail {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}

.table-box {
  height: 500px;
  overflow-y: auto;
  padding-right: 8px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: rgba(57, 70, 100, 0.4);
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: #ccc;
  }
}
</style>

